.ant-tabs-nav {
  width: fit-content;
  border-bottom: 1px solid #f0f0f0;
}

.ant-tabs-nav::before {
  display: none; /* This hides the original full-width border */
}

.ant-tabs-content {
  width: 100%;
}

.select-with-icon {
  display: flex;
  align-items: center;
  position: relative;
}

.select-icon {
  position: absolute;
  left: 10px; /* Adjust as needed */
  z-index: 10;
}

.select-box .ant-select-selector {
  padding-left: 30px; /* Adjust as needed, should be enough to make space for the icon */
}
