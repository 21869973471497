/* .custom-dots .ant-carousel .slick-dots li button {
  background: #fff;
  border-radius: 50%;
}

.custom-dots .ant-carousel .slick-dots li.slick-active button {
  background: #1890ff;
}

.thumbnails-wrapper {
  display: flex;
  overflow-x: auto;
  gap: 8px; /* Adjust spacing between thumbnails 
}

.thumbnail {
  cursor: pointer;
}

.thumbnail.selected {
  border: 2px solid #1890ff; /* Highlight color for selected thumbnail 
}

.thumbnails-wrapper {
  display: flex;
  overflow-x: auto;
  gap: 8px; /* Adjust the space between thumbnails 
}

.thumbnail {
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s;
}

.thumbnail.selected {
  opacity: 1; /* Highlight the selected thumbnail 
}

.thumbnail-strip {
  display: flex;
  overflow-x: auto;
  margin-top: 16px; /* Adjust as needed 
}

.thumbnail {
  cursor: pointer;
  border: 2px solid transparent; /* Adjust as needed 
  padding: 4px; /* Adjust as needed 
}

.thumbnail.active {
  border-color: #1890ff; /* Highlight color when selected 
}

.carousel-controls {
  display: flex;
  justify-content: center;
  margin-top: 16px; /* Adjust as needed 
}

/* Style for the main Carousel slides 
.slide {
  text-align: center;
  background: #364d79;
  line-height: 160px; /* Adjust based on your image height 
  overflow: hidden;
}

.slide img {
  max-width: 100%;
  max-height: 160px; /* Adjust based on your image height 
} 
*/

/* AssetDetails.css */

.carousel-and-thumbnails {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the carousel and thumbnails */
  width: 50%; /* Set the width to take up half the page */
  margin-bottom: 24px; /* Space below the carousel */
}

.slide img {
  max-width: 100%;
  height: auto; /* Ensure image scales correctly */
}

.thumbnail-strip {
  display: flex;
  overflow-x: auto; /* Allows horizontal scrolling for thumbnails */
  gap: 8px; /* Space between thumbnails */
  justify-content: center; /* Center thumbnails if they don't fill the width */
  margin-top: 16px; /* Space between the carousel and thumbnails */
}

.thumbnail {
  cursor: pointer;
  border: 2px solid transparent; /* Transparent border to maintain layout when selected */
  transition: border-color 0.3s; /* Smooth transition for the border color */
}

.thumbnail.active {
  border-color: #1890ff; /* Highlight color when selected */
}

/* Ensure no outline appears on focus for a cleaner look, but be sure to provide an alternative focus indicator for accessibility */
.thumbnail:focus {
  outline: none;
}

/* Add custom controls for larger clickable areas if needed */
.carousel-controls {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.carousel-controls .ant-btn {
  height: auto;
  padding: 8px; /* Larger area for easier click */
}

/* Apply this class to your main Carousel's container div if you want to hide the arrows */
.ant-carousel .slick-arrow {
  display: none !important; /* Use important to override default styles */
}

